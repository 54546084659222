import styled from '@emotion/styled';
import { Button, Popover as MuiPopover, PopoverProps as MuiPopoverProps } from '@mui/material';
import { clsx } from 'clsx';
import { CSSProperties, PropsWithChildren, useCallback, useRef, useState } from 'react';
import { Authorized } from '../Authorized';
import { Link } from 'react-router-dom';
import { User as RealmUser } from 'realm-web';
import { logout } from '../../api/api';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { selectUser, setUserId } from '../../redux/user/userSlice';
import { useLog } from '../../hooks/useLog';
import { logEvent } from '@amplitude/analytics-browser';
import { combine } from '../../utils/utils';
import { TaxonomyEventActions, TaxonomyEventNames } from '../../types';

interface PopoverMenuProps extends PropsWithChildren {
  className?: string;
  style?: CSSProperties;
}

export const PopoverMenu = styled(({ className, style, children }: PopoverMenuProps) => {
  const [isShowPopover, setShowPopover] = useState(false);
  const buttonEl = useRef<HTMLButtonElement>(null);
  const showPopover = useCallback(() => setShowPopover(true), []);
  const closePopover = useCallback(() => setShowPopover(false), []);

  return (
    <>
      <Button className={className} style={style} ref={buttonEl} onClick={showPopover}>
        {children}
      </Button>
      <Popover
        anchorEl={buttonEl.current}
        anchorOrigin={{
          horizontal: 'left',
          vertical: 'bottom',
        }}
        open={isShowPopover}
        onClose={closePopover}
      >
        &nbsp;
      </Popover>
    </>
  );
})`
  padding: 4px;
  margin: 0;
  min-width: 0;
`;

interface PopoverProps extends PropsWithChildren, MuiPopoverProps {
  className?: string;
  style?: CSSProperties;
}

const Popover = styled(({ className, style, ...others }: PopoverProps) => {
  const user = useAppSelector(selectUser);
  const dispatch = useAppDispatch();
  const log = useLog();

  const handleLogout = async () => {
    if (user === null) return;

    try {
      logout(user);
      dispatch(setUserId(null));
      log.success('Succeed to logout');
    } catch (error: any) {
      log.error(error.error);
    }
  };

  const handleClickOptions = useCallback(() => {
    logEvent(combine([TaxonomyEventActions.tap, TaxonomyEventNames.options]));
  }, []);

  const handleClickCharts = useCallback(() => {
    logEvent(combine([TaxonomyEventActions.tap, TaxonomyEventNames.charts]));
  }, []);

  const handleClickLogin = useCallback(() => {
    logEvent(combine([TaxonomyEventActions.tap, TaxonomyEventNames.login]));
  }, []);

  return (
    <MuiPopover className={clsx(className, 'Popover')} style={style} {...others}>
      <Authorized
        fallback={
          <>
            <Link to='/modal/login'>
              <PopoverItem color='primary' onClick={handleClickLogin}>
                Login
              </PopoverItem>
            </Link>
          </>
        }
      >
        <Link to='/modal/options'>
          <PopoverItem color='inherit' onClick={handleClickOptions}>
            Options
          </PopoverItem>
        </Link>
        <Link to='/modal/charts'>
          <PopoverItem color='inherit' onClick={handleClickCharts}>
            Charts
          </PopoverItem>
        </Link>
        <PopoverItem color='primary' onClick={handleLogout}>
          Logout
        </PopoverItem>
      </Authorized>
    </MuiPopover>
  );
})`
  margin-top: 8px;
  max-width: auto;
  .MuiPaper-root {
    display: flex;
    flex-direction: column;
  }
`;

const PopoverItem = styled(Button)`
  width: 140px;
  height: 48px;
  text-align: left;
  min-width: auto;
  padding: 0;
`;
