import { Button, styled } from '@mui/material';
import { HamburgerMenuIcon, LogoFocuskeeperFullSvg, userProfileEmptyImage } from '../../assets';
import { CSSProperties, useCallback } from 'react';
import InlineSVG from 'react-inlinesvg';
import { Link } from 'react-router-dom';
import { useAppSelector } from '../../redux/store';
import { selectUser, selectUsername } from '../../redux/user/userSlice';
import { ResponsiveVisibility } from '../ResponsiveVisibility';
import { Authorized } from '../Authorized';
import { PopoverMenu } from '../PopoverMenu';
import { combine } from '../../utils/utils';
import { logEvent } from '@amplitude/analytics-browser';
import { TaxonomyEventActions, TaxonomyEventNames } from '../../types';

export const Header = () => {
  const user = useAppSelector(selectUser);
  const userName = useAppSelector(selectUsername);
  const userProfilePicture = user?.profile?.pictureUrl || userProfileEmptyImage;

  const handleClickLogin = useCallback(() => {
    logEvent(combine([TaxonomyEventActions.tap, TaxonomyEventNames.login]));
  }, []);

  return (
    <Wrapper>
      <Container>
        <Link to={'/'}>
          <Logo />
        </Link>
        <ResponsiveVisibility
          type={'up'}
          responsive={{
            lg: (
              <>
                <Navigation>
                  <NavigationItem to={'/modal/options'}>Timer Option</NavigationItem>
                  <NavigationItem to={'/modal/charts'}>Report</NavigationItem>
                </Navigation>
                <ControlButtons>
                  <Authorized
                    fallback={
                      <Link to='/modal/login'>
                        <LoginButton variant='contained' onClick={handleClickLogin}>
                          Login
                        </LoginButton>
                      </Link>
                    }
                  >
                    {user && (
                      <PopoverMenu>
                        <Avatar src={userProfilePicture} alt={userName}></Avatar>
                      </PopoverMenu>
                    )}
                  </Authorized>
                </ControlButtons>
              </>
            ),
          }}
        />
        <ResponsiveVisibility
          type={'down'}
          responsive={{
            lg: (
              <ControlButtons>
                <PopoverMenu>
                  <img src={HamburgerMenuIcon} alt='More' />
                </PopoverMenu>
              </ControlButtons>
            ),
          }}
        ></ResponsiveVisibility>
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled('header')`
  width: 100%;
`;

const Container = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: auto;
  max-width: 1366px;
  width: 100%;
  height: 72px;
  padding: 0 67px;
  ${({ theme }) => theme.breakpoints.down('lg')} {
    padding: 0 20px;
  }
`;

interface LogoProps {
  className?: string;
  style?: CSSProperties;
}

const Logo = styled(({ className, style }: LogoProps) => {
  return <InlineSVG className={className} style={style} src={LogoFocuskeeperFullSvg} />;
})`
  width: 136px;
`;

const Navigation = styled('nav')`
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 60px;
`;

const NavigationItem = styled(Link)`
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 21px */
`;

const ControlButtons = styled('div')`
  display: flex;
  align-items: center;
  column-gap: 8px;
`;

const LoginButton = styled(Button)`
  height: 40px;
  border-radius: 8px;
`;

const NoAvatar = styled('div')`
  width: 42px;
  height: 42px;
  border-radius: 50%;
  border: none;
  background-color: #fff;
  outline: none;
`;

const Avatar = styled('img')`
  appearance: none;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  border: none;
  background-color: #fff;
  outline: none;
`;
